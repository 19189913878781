<template>
  <t-modal
    :name="`dynamicInvoiceModal`"
    :variant="`2xl`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <div class="invoice-modal">
      <a href="#" @click.prevent="sendEmail" class="out-modalbox">
        <img src="@/assets/placeholder/mail.svg" alt="" />
        <span>{{
          $t(
            'components.tripManagement.details.modals.dynamicInvoiceModal.sendViaMail'
          )
        }}</span>
      </a>
      <a href="#" @click.prevent="exportToPDF" class="out-modalbox">
        <img src="@/assets/placeholder/download.svg" alt="" />
        <span>{{
          $t(
            'components.tripManagement.details.modals.dynamicInvoiceModal.downloadAsPDF'
          )
        }}</span>
      </a>
      <div ref="document">
        <div v-html="htmlString"></div>
      </div>
    </div>
  </t-modal>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
  name: 'DynamicInvoiceModal',

  props: {
    htmlString: {
      type: String,
    },
    id: {
      type: String,
    },
    pdfName: {
      type: String,
      default: 'invoice',
    },
  },

  methods: {
    sendEmail() {
      this.$emit('send-email', this.id)
    },
    exportToPDF() {
      html2pdf(this.$refs.document, {
        margin: 0.2,
        filename: `${this.pdfName}-${this.id}.pdf`,
        image: { type: 'png', quality: 0.9 },
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
      })
    },
  },
}
</script>

<style lang="scss">
.invoice-modal {
  .out-modalbox {
    background: #fff;
    padding: 7px 10px;
    z-index: 50;
    display: flex;
    transition: all 0.3s ease-in-out;

    // the position values below are placed by experimenting / simulating responsiveness on browser
    position: fixed !important;
    top: 50%;
    right: 0;
    @media (min-width: 850px) {
      right: 1%;
    }
    @media (min-width: 950px) {
      right: 5%;
    }
    @media (min-width: 1200px) {
      right: 12%;
    }
    @media (min-width: 1500px) {
      top: 45%;
      right: 14%;
    }

    span {
      transition: all 0.3s ease-in-out;
      font-size: 0;
    }
    &:hover {
      span {
        display: inline-block;
        width: auto;
        margin-left: 15px;
        right: 0;
        position: relative;
        font-size: 13px;
      }
    }
    & + .out-modalbox {
      top: calc(50% + 50px);
    }
    @media (min-width: 1500px) {
      & + .out-modalbox {
        top: calc(45% + 50px);
      }
    }
  }

  .image-header {
    min-height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    padding: 20px 70px 20px 55px;
    color: #fff;
    div {
      .date {
        font-size: 13px;
      }
      img {
        max-width: 140px;
        margin-top: 30px;
      }
      h2 {
        font-size: 24px;
      }
      span {
        font-size: 15px;
        margin-bottom: 27px;
      }
    }
  }
  .content {
    padding: 0 70px 16px;
  }
  .map_wrapper {
    margin-top: 20px;
    padding-bottom: 50px;
    display: flex;
    .directions {
      flex: 0 0 50%;
      div {
        display: flex;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .copyright {
    img {
      margin: auto;
    }
  }
  .total-fare-text {
    font-size: 24px;
    font-weight: bold;
    color: #121311;
  }
}
</style>
